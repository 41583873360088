import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Update June 7, 2018 - Hall of Shame`}</h3>
    <p>{`This article is a Hall of Shamer`}{`™`}{` for irrelevance.`}</p>
    <p>{`Hello there! Hopefully you are here because you want to learn JavaScript. Odds
are you have come here from another language and want to punch all of us
hipsters in the neck for naming our projects after things that do not even sound
vaguely related to what they actually do, you know...
`}<a parentName="p" {...{
        "href": "http://backbonejs.org/"
      }}>{`Backbone.js`}</a>{`, `}<a parentName="p" {...{
        "href": "http://expressjs.com/"
      }}>{`Express`}</a>{`,
`}<a parentName="p" {...{
        "href": "http://mustache.github.com/"
      }}>{`Mustache`}</a>{`, etc. I imagine diving into JavaScript
with its plethera of transpilers, absurd amount of frameworks, libraries,
awkward language decisions, and multiple environments is a daunting task.`}</p>
    <p>{`My aim in this series of posts is to give you an idea of the different types of
problems we face as JavaScript developers, why we have so many libraries and
frameworks, and also give you a brief overview of some of the options that
exists. It is my hope that when this is all over you can walk away with an
understanding of some of the projects you may have already heard of, projects
such as `}<a parentName="p" {...{
        "href": "http://coffeescript.org/"
      }}>{`CoffeeScript`}</a>{`.`}</p>
    <p>{`Alright that as far as introductions go, thats all I got. Now, head over to the
first post `}<a parentName="p" {...{
        "href": "dom-libraries.html"
      }}>{`The DOM Problem`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      